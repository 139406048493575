angular.module('estimationService', []).factory('Estimation', function($http) {

	return {
		destroy: function(estimationId) {
			return $http({
				method: 'DELETE',
				url: 'estimations/' + estimationId,
			});
		},
	};
});

