angular.module('routeService', []).factory('Route', function() {

    return {

        getAction: function() {
            
            var parts = location.pathname.split('/').reverse();
            var action, id = null;

            switch (parts[0]) {
                case 'edit':
                    action = 'EDIT';
                    id = parts[1];
                    break;
                case 'create':
                    action = 'CREATE';
                    break;
                default:
                    if (!isNaN(parts[0])) {
                        action = 'SHOW';
                        id = parts[0];
                    }
                    else {
                        action = 'INDEX';
                    }
            }

            return [action, id];
        }
    };
});
