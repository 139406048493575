angular.module('dealTypesController', []).controller('dealTypesController', function($scope, $translate, DealType, Alert) {

    $scope.loading = false;

    var hiddenDealTypes = [];

    DealType.get().success(function (data) { $scope.dealTypes = data; });
    
    $scope.displayDealType = function(dealTypeId) {
        return !_.contains(hiddenDealTypes, dealTypeId);
    };

    $scope.destroyDealType = function(dealTypeId) {        
        Alert.confirm(function() { return DealType.destroy(dealTypeId) }, function() { hiddenDealTypes.push(dealTypeId) });
    };

    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };
});