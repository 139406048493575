angular.module('scanService', []).factory('Scan', function($http) {

    return {
        store: function(data) {
            return $http({
                method: 'POST',
                url: 'scan',
                data: data,
            }); 
        },
        update: function(id, data) {
            return $http({
                method: 'PUT',
                url: 'scan/' + id,
                data: data,
            }); 
        },
        complete: function(id, data) {
            return $http({
                method: 'POST',
                url: 'scan/' + id + '/complete',
                data: data,
            }); 
        },
        search: function(userId) {
            return $http({
                method: 'GET',
                url: 'scan/search/' + userId,
            }); 
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'scan/' + id,
                data: {_method: 'DELETE'},
            }); 
        },
    };
});
