angular.module('skillService', []).factory('Skill', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/skills',
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'admin/skills/' + id,
                data: {_method: 'DELETE'},
            });
        },
    };
});
