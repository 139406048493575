angular.module('purchasesController', []).controller('purchasesController', function($translate, $scope, Article, Operation, Deal, Alert, Purchase) {

    $scope.loading = false;

    $scope.purchased_at = new Date();

    $scope.selectedItems = {};
    $scope.operations = [];

    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };

    $scope.settingsOperations = {
        enableSearch: true, 
        idProp: 'id',
        displayProp: "designation",
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200,
        showCheckAll: false,
        showUncheckAll: false,
        selectionLimit: 1
    };

    $scope.translateOperation = {buttonDefaultText: 'Selection d\'une opération', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'opération(s) sélectionnée(s)', searchPlaceholder: 'Rechercher...'}

    $scope.onArticleSelect = function ($item, $model, $label) {
        $scope.selectedDesignation = $item.designation
    };

    Article.get().success(function (data) { $scope.articles = data; });
    Deal.get().success(function (data) { $scope.deals = data; });

    //get selected categories for edit providers
    $scope.getOperations = function(affaireDesignation) {
        Operation.getOperations(affaireDesignation).success(function (data) { $scope.operations = data; });
    };

    $scope.getSelectedDeal = function(affaireId) {
        if (affaireId) {
            Deal.getDeal(affaireId).success(function (data) { $scope.selectedAffaire = data; });
        }
    };

    $scope.destroyPurchase = function(purchaseId) {        
        Alert.confirm(function() { return Purchase.destroy(purchaseId) }, function() { location.reload(); });
    };

    $scope.isStocked = function(purchaseId) {
        Purchase.isStocked(purchaseId).success(function (data) { $scope.checked = data.stocked; });
    }
    
}); 
