angular.module('stockService', []).factory('Stock', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'stocks',
            });
        },
        destroy: function(stockId) {
            return $http({
                method: 'POST',
                url: 'stocks/' + stockId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
