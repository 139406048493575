angular.module('rolesController', []).controller('rolesController', function($scope, Role, Alert) {

    $scope.loading = false;

    var visibleRoles = [];
    var destroyedRoles = [];

    $scope.toggleDisplayRole = function(roleId) {
        if (_.contains(visibleRoles, roleId)) {
            _.pull(visibleRoles, roleId);
        }
        else {
            visibleRoles.push(roleId);
        }
    };

    $scope.slidePermissionList = function(className) {
        $('.tr-perm').not(className).hide().end().parent().find(className).slideToggle('slow');
    };

    $scope.isPermissionListVisible = function() {
        return !_.isEmpty(visibleRoles);
    };

    $scope.isRoleVisible = function(roleId) {
        return _.contains(visibleRoles, roleId);
    };

    $scope.isRoleDestroyed = function(roleId) {
        return _.contains(destroyedRoles, roleId);
    };

    $scope.destroyRole = function (roleId) {
        Alert.confirm(function() { return Role.destroy(roleId) }, function() { 
            destroyedRoles.push(roleId) 
            if (_.contains(visibleRoles, roleId)) {
                _.pull(visibleRoles, roleId);
            }
        });
    };

    $scope.toggleRolePermission = function(roleId, permissionId) {

        $scope.loading = true;

        Role.togglePermission(roleId, permissionId)
            .success(function(data) { $scope.loading = false; })
            .error(function(data) { console.log(data); });
    };
});