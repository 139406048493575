angular.module('orderService', []).factory('Order', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'orders',
            });
        },
        getEdit: function(orderId) {
            return $http({
                method: 'GET',
                url: 'orders/'+ orderId +'/edit',
            });
        },
        destroy: function(orderId) {
            return $http({
                method: 'POST',
                url: 'orders/' + orderId,
                data: {_method: 'DELETE'},
            });
        },
        getStats: function() {
            return $http({
                method: 'GET',
                url: 'orders/stats'
            });
        },
    };
});
