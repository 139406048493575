angular.module('skillsController', []).controller('skillsController', function($scope, Skill, Alert) {

    $scope.loading = false;

    var hiddenSkills = [];

    Skill.get().success(function (data) { $scope.skills = data; });
    
    $scope.displaySkill = function(skillId) {
        return !_.contains(hiddenSkills, skillId);
    };

    $scope.destroySkill = function(skillId) {        
        Alert.confirm(function() { return Skill.destroy(skillId) }, function() { hiddenSkills.push(skillId) });
    };
});