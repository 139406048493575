angular.module('machinesController', []).controller('machinesController', function($scope, Machine, Alert) {

    $scope.loading = false;

    var hiddenMachines = [];

    Machine.get().success(function (data) { $scope.machines = data; });
    
    $scope.displayMachine = function(machineId) {
        return !_.contains(hiddenMachines, machineId);
    };

    $scope.destroyMachine = function(machineId) {        
        Alert.confirm(function() { return Machine.destroy(machineId) }, function() { hiddenMachines.push(machineId) });
    };
});