angular.module('checkingController', []).controller('checkingController', function($translate, $scope, $filter, $http, $location, $window, $routeParams, User, Deal, Checking, Alert) {

	$scope.rollback_operation_id = null;

    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };
    
	$scope.login = $routeParams.login;
	$scope.password = null;
	$scope.operation = null;
    $scope.hours = null;
    $scope.hour_at = new Date(1970, 0, 1, new Date().getHours(), new Date().getMinutes(), 0);
    $scope.yesterday = 'false';
    $scope.comment = null;

    if ($location.path() == '/step3') {
        Checking.lastCheck().success(function (data) {
            if (data.time) {
                $scope.hour_at = new Date(1970, 0, 1, data.time.hours, data.time.minutes, 0);
            }
        });
    }

    if ($routeParams.success != null) {
        $scope.success = $routeParams.success === 'true';
        $scope.error = $routeParams.success === 'false'; 
    }
    else {
        $scope.success = false;
        $scope.error = false;
    }

	User.getJSON().success(function (data) { $scope.users = data; });
    Deal.getJSON().success(function (data) {
		$scope.deals = data;
		if (data.length) {
			if (!$scope.rollback_operation_id) {
				$scope.selectedDeal = data[0];
				if (data[0].operations.length) {
					$scope.operation = data[0].operations[0];
				}
			} else {
				var find = false;
				for (var i=0; i < data.length; i++) {
					if (find === true) { break; }
					if (data[i].operations.length) {
						for (var j=0; j < data[i].operations.length; j++) {
							if (data[i].operations[j].id === $scope.rollback_operation_id) {
								find = true;
								$scope.selectedDeal = data[i];
								$scope.operation = data[i].operations[j];
								break;
							}
						}
					}
				}
			}
		}
	});
    
    if ($routeParams.login != null) {
        Checking.log($routeParams.login).success(function (data) { 
            $scope.log = data.log; 
            $scope.badge = data.badge;
            $scope.dates = data.dates;
        });
    }
    
	$scope.go = function (hash) { 
		$location.path(hash);
 	};

 	$scope.goToStep2 = function(login) {
 		$location.path('/step2/' + login);
 	};

	$scope.auth = function(goNextStep) {
		$scope.$parent.loading = true;
		Checking.auth($scope.login, $scope.password).success(function (data) {
        	if (data.success) {
        		$scope.error = false;
        		if (goNextStep) {
        			$scope.$parent.loading = false;
        			$location.path('/step3');
        		}
        		else {
        			$scope.operation = null;
                    $scope.hours = null;
        			$scope.check(true);
        		}
        	}
        	else {
    			$scope.$parent.loading = false;
        		$scope.error = true;
        	}
        });
	};

	$scope.check = function(goNextStep) {
		$scope.$parent.loading = true;
		Checking.check($scope.operation != null ? $scope.operation.id : null, $scope.hours, $scope.hour_at.toTimeString().substr(0,5), $scope.yesterday, $scope.comment).success(function (data) {
        	$scope.$parent.loading = false;
            if (data.success) {
        	    $scope.success = true;
                $scope.error = false;
                if (goNextStep) {
                    $location.path('/step1/' + data.success);
                }
                $scope.selectedDeal = null;
                $scope.operation = null;
                $scope.hours = null;
                $scope.hour_at = new Date(1970, 0, 1, new Date().getHours(), new Date().getMinutes(), 0);
                $scope.yesterday = 'false';
                $scope.comment = null;
                $window.location.reload();
            }
            else {
                $scope.error = true;
                $scope.msgError = data.msg;
                $scope.success = false;
            }
        });
	};
    
    $scope.logout = function() {
		$scope.$parent.loading = true;
        Checking.logout().success(function (data) {
        	$scope.$parent.loading = false;
            $location.path('/');
        });
    };
    
    /**
     * Index
     */
    
    var hidden = [];

    $scope.displayChecking = function(id) {
        return !_.contains(hidden, id);
    };

    $scope.destroyCheck = function(id) {        
        Alert.confirm(function() { return Checking.destroy(id) }, function() { 
            hidden.push(id);
            $('#editChecking' + id).modal('hide');
            window.location.reload();
        });
    };

	$scope.updateDeal = function (operation_id){
		angular.forEach($scope.deals, function(deal, key) {
			angular.forEach(deal.operations, function(operation, key) {
				if (operation.id == operation_id) {
					$scope.selectedDeal = deal;
					$scope.operation = operation;
				}
			});
		});
	};
});