angular.module('addressService', []).factory('Address', function($http) {

    return {
        destroy: function(addressId) {
            return $http({
                method: 'POST',
                url: 'addresses/' + addressId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
