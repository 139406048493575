angular.module('usersController', []).controller('usersController', function($scope, User, Alert, Skill) {

    $scope.loading = false;

    var hiddenUsers = [];

    User.get().success(function (data) { $scope.users = data; });
    
    $scope.displayUser = function(userId) {
        return !_.contains(hiddenUsers, userId);
    };

    $scope.destroyUser = function(userId) {        
		User.destroy(userId) ;
		hiddenUsers.push(userId) ;
    };

    /**
     * Get all available skills
     */
    Skill.get().success(function (data) { $scope.skills = data; });

    /**
     * Get skills associated with selected user
     */
    $scope.selectedSkills = [];
    $scope.selectedUserSkills = function(userId) {
        User.getSkills(userId).success(function (data) { $scope.selectedSkills = data; });
    };

    $scope.settingsSkills = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };
    $scope.translateSkill = {buttonDefaultText: 'Selection des compétences', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'compétence(s) sélectionnée(s)', searchPlaceholder: 'Rechercher...'}

});