angular.module('scanController', []).controller('scanController', function( $scope, $filter, Checking, Scan, Alert) {

    document.getElementById("user").focus();

    $scope.user_badge  = null;
    $scope.user        = null;
    $scope.piece_badge = null;
    $scope.piece       = null;
    $scope.operation   = null;
    $scope.scan        = null;
    $scope.comment     = "";

    $scope.skillsError = false;
    $scope.pieceError = false;
    $scope.userError  = false;
    $scope.success    = null;
    $scope.error      = null;

    // SCAN USER METHOD
    $scope.scan_user = function(user_badge){
        $scope.user = null;
        $scope.scan = null;

        Checking.scanUser(user_badge).success(function(data){
            $scope.userError = !data.success;
            if(data.success == true) {
                $scope.user = data.user;

                // SEARCH SCAN ENTRY FOR CURRENT USER
                Scan.search(data.user.id)
                    .success(function(data){
                        if(data.scan != null) {
                            $scope.scan = data.scan;
                        } else {
                            setTimeout(function(){
                                document.getElementById("piece").focus();
                            }, 500);
                        }
                    });
            } else {
                $scope.user_badge = null;
            }
        });
    };

    // SCAN PIECE METHOD
    $scope.scan_piece = function(piece_badge, userId){
        $scope.piece = null;
        $scope.operation = null;
        $scope.pieceError = false;
        $scope.skillsError = false;
        Checking.scanPiece(piece_badge, userId).success(function(data){
            if(data.success == true) {
                $scope.piece     = data.piece;
                $scope.operation = data.operation;
                setTimeout(function(){
                    $scope.start();
                }, 3000);
            } else {
                if (data.cause == 'skill') {
                    $scope.skillsError = true;
                } else {
                    $scope.pieceError = true;
                }
                $scope.piece_badge = null;
            }
        });
    };

    // Start scan function
    $scope.start = function() {
        Scan.store({user_id: $scope.user.id, operation_id: $scope.operation.id, piece_id: $scope.piece.id})
            .success(function(data){
                $scope.success = true;
                setTimeout(function(){
                    location.reload();
                }, 3000);
            });
    };

    $scope.destroy = function(id) {
        Scan.destroy(id)
            .success(function(data){
                $scope.success = true;
                setTimeout(function(){
                    location.reload();
                }, 3000);
            });
    };

    $scope.complete = function(id) {
        Scan.complete(id, {comment: $scope.comment})
            .success(function(data){
                $scope.success = true;
                setTimeout(function(){
                    location.reload();
                }, 3000);
            });
    };

    $scope.reset = function() {
        $scope.user_badge = null;
        $scope.user = null;
        $scope.operation_badge = null;
        $scope.operation = null;
        $scope.scan = null;

        $scope.operationError = false;
        $scope.userError = false;
        $scope.comment = "";
    };

});