angular.module('providerService', []).factory('Provider', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'providers',
            });
        },
        getEdit: function(providerId) {
            return $http({
                method: 'GET',
                url: 'providers/'+ providerId +'/edit',
            });
        },
        destroy: function(providerId) {
            return $http({
                method: 'POST',
                url: 'providers/' + providerId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
