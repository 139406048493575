angular.module('devisController', []).controller('devisController', function($translate, $scope, Deal, Machine, Client, SweetAlert, Devis, Alert, Article, Payment, Line, Invoice, Avoir) {

    $scope.loading = false;

    var hiddenItems = [];

    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };

    $scope.selectedDesignation = {};
    $scope.addresses = [];
    $scope.address = null;
    $scope.errorAddress = null;
    $scope.address_id = null;
    $scope.preSelectedDeal = null;
    $scope.preSelectedClient = null;
    $scope.preSelectedInvoice = null;
    $scope.selectedInvoice = null;
    $scope.has_invoice = true;

    Devis.get().success(function (data) {
        $scope.devis = data;
    });
    Devis.getInvoices().success(function (data) {
        $scope.invoices = data;
        if($scope.preSelectedInvoice)
        {
            for(var i = 0; i < $scope.invoices.length; i++)
            {
                if($scope.invoices[i].id == $scope.preSelectedInvoice) {
                    $scope.selectedInvoice = $scope.invoices[i];
                    break;
                }
            }
        }
     });

    Article.get().success(function (data) { $scope.articles = data; });

    $scope.display = function(id) {
        return !_.contains(hiddenItems, id);
    };

    $scope.getInvoiceClient = function() {

        if($scope.has_invoice = true  && $scope.selectedInvoice)
        {
            if($scope.selectedInvoice.client_id)
            {
                $scope.preSelectedClient = $scope.selectedInvoice.client_id;
                for (var j = 0; j < $scope.clients.length; j++) {
                    if($scope.clients[j].id == $scope.preSelectedClient) {
                        $scope.selectedDesignation = $scope.clients[j];
                        $scope.checkAddresses();
                    }
                }
            }
        }
        $scope.has_invoice = true;
    };


    Client.get().success(function (data) {
        $scope.clients = data;

        if (!$scope.clients.length) {
            $scope.add_client = 1;
        } else {
            if ($scope.preSelectedClient) {
                for (var j = 0; j < $scope.clients.length; j++) {
                    if($scope.clients[j].designation == $scope.preSelectedClient) {
                        $scope.selectedDesignation = $scope.clients[j];
                        if ($scope.selectedDesignation.addresses && $scope.preSelectedAddress) {
                            for (var k = 0; k < $scope.selectedDesignation.addresses.length; k++) {
                                if($scope.selectedDesignation.addresses[k].id == $scope.preSelectedAddress) {
                                    $scope.add_address = 2;
                                    $scope.address = $scope.selectedDesignation.addresses[k];
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
            }
            if($scope.errorAddress == 1){
                $scope.add_address = 1;
            }
        }
    });

    // Association avec les affaires
    Deal.get().success(function (data) {
        $scope.deals = data;
        if ($scope.preSelectedDeal) {
            for (var i = 0; i < $scope.deals.length; i++) {
                if($scope.deals[i].id == $scope.preSelectedDeal) {
                    $scope.selectedDeal = $scope.deals[i];
                    break;
                }
            }
        }
    });

    $scope.checkAddresses = function () {
        for (var i = 0; i < $scope.clients.length; i++) {
            if($scope.selectedDesignation == $scope.clients[i]){
                $scope.add_client = 2;
                break;
            }
        }

        if ($scope.selectedDesignation.addresses) {
            $scope.add_address = 2;
            $scope.address = $scope.selectedDesignation.addresses[0];
        }
        else{
            $scope.add_address = 1;
        }
    };

    $scope.onArticleSelect = function ($item, $model, $label, e) {
        if (e != '') {
            $scope['editDesignation' + e] = $item.designation
        } else {
            $scope.designation = $item.designation
        }
    };

    $scope.destroyDevis = function(id) {
        Alert.confirm(function() { return Devis.destroy(id) }, function() { hiddenItems.push(id) });
    };

    $scope.destroyDevisView = function(id) {
        Alert.confirm(function() { return Devis.destroy(id) }, function() {
            location.replace('/devis');
        });
    };

    $scope.destroyLine = function(id) {
        Alert.confirm(function() { return Line.destroy(id) }, function() { location.reload(); });
    };

    $scope.destroyPayment = function(id) {
        Alert.confirm(function() { return Payment.destroy(id) }, function() {  hiddenItems.push(id) });
    };

    $scope.destroyInvoice = function (id) {
        Alert.confirm(function() { return Invoice.destroy(id) }, function() {
            location.replace('/invoices');
        });
    };

    $scope.destroyAvoir = function(id) {
        Alert.confirm(function() { return Avoir.destroy(id) }, function() {
            location.replace('/avoirs');
        });
    };

    $scope.settingsTypes = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };

    $scope.settingsContacts = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "fullNameDesignation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };
});
