angular.module('clientService', []).factory('Client', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'clients',
            });
        },
        destroy: function(clientId) {
            return $http({
                method: 'POST',
                url: 'clients/' + clientId,
                data: {_method: 'DELETE'},
            });
        },
        getAddresses: function(clientId) {
            return $http({
                method: 'GET',
                url: 'clients/addresses/' + clientId,
            });
        },
        getContact: function(clientId) {
            return $http({
                method: 'GET',
                url: 'clients/contacts/' + clientId,
            });
        },
    };
});
