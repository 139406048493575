angular.module('articleCategoriesController', []).controller('articleCategoriesController', function($scope, ArticleCategory, Alert) {

    $scope.loading = false;

    var hiddenArticleCategories = [];

    ArticleCategory.get().success(function (data) { $scope.articleCategories = data; });
    
    $scope.displayArticleCategory = function(articleCategoryId) {
        return !_.contains(hiddenArticleCategories, articleCategoryId);
    };

    $scope.destroyArticleCategory = function(articleCategoryId) {        
        Alert.confirm(function() { return ArticleCategory.destroy(articleCategoryId) }, function() { hiddenArticleCategories.push(articleCategoryId) });
    };
});