angular.module('checkingService', []).factory('Checking', function($http) {

    return {

      	auth: function(login, password) {
            return $http({ 
                method: 'POST',
                url: 'checking/auth',
                data: {
                    login:    login,
                    password: password
                }
            })
        },  
        check: function(operation, hours, hour_at, yesterday, comment) {
            return $http({
                method: 'POST',
                url: 'checking/check',
                data: {
                    operation: operation,
                    hours: hours,
                    hour_at: hour_at,
                    yesterday: yesterday,
                    comment : comment
                }
            })
        },
        scanUser: function(userId) {
            return $http({
                method: 'GET',
                url:'scanUser/'+ userId,
            })
        },
        scanPiece: function(pieceId, userId) {
            return $http({
                method: 'GET',
                url:'scanPiece/'+ pieceId + '/' + userId,
            })
        },
        logout: function() {
            return $http({
                method: 'GET',
                url: 'logout'
            })
        },
        log: function(login) {
            return $http({
                method: 'GET',
                url: 'checking/log/' + login
            })
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'checking/manage/' + id,
                data: {_method: 'DELETE'},
            }); 
        },
        lastCheck: function() {
            return $http({
                method: 'GET',
                url: 'checking/last',
            });
        }
    };
});
