angular.module('documentService', []).factory('Document', function($http) {

    return {
        destroy: function(documentId) {
            return $http({
                method: 'POST',
                url: 'documents/' + documentId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
