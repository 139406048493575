angular.module('contactService', []).factory('Contact', function($http) {

    return {
        destroy: function(contactId) {
            return $http({
                method: 'POST',
                url: 'contacts/' + contactId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
