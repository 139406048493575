angular.module('articlesController', []).controller('articlesController', function($scope, Article, ArticleCategory, Alert, Provider) {

    $scope.loading = false;

    var hiddenArticles = [];

    Provider.get().success(function (data) { $scope.providers = data; });
    Article.get().success(function (data) { $scope.articles = data; });
    
    $scope.displayArticle = function(articleId) {
        return !_.contains(hiddenArticles, articleId);
    };

    $scope.destroyArticle = function(articleId) {        
        Alert.confirm(function() { return Article.destroy(articleId) }, function() { hiddenArticles.push(articleId) });
    };

    //get selected categories for edit providers
    $scope.selectedCategories = function(articleId) {
        Article.getEdit(articleId).success(function (data) { $scope.selectedItems = data; });
    };

    //get list of categories
    ArticleCategory.get().success(function (data) { $scope.categories = data; });

    $scope.selectedItems = [];
    
	$scope.settingsCategories = {
        enableSearch: true, 
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id', 
        buttonClasses: "btn-dropdown", 
        scrollable: true,
        scrollableHeight: 200
    };

    $scope.translateSelect = {buttonDefaultText: 'Selection des catégories d\'articles'}
}); 
