angular.module('technicalService', []).factory('Technical', function($http) {

	return {
		destroy: function(id) {
			return $http({
				method: 'DELETE',
				url: 'technicals/' + id,
			});
		},
	};
});

