angular.module('settingsController', []).controller('settingsController', function($scope, Upload, PaymentMethod, PaymentTime, Alert) {

    var hiddenMethods = [];
    var hiddenTimes = [];

    $scope.$watch('files', function () {
        $scope.upload($scope.files);
    });

    $scope.upload = function (files) {
        if (files && files.length) {
            Upload.upload({
                url: 'admin/settings',
                file: files[0]
            }).progress(function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            }).success(function (data, status, headers, config) {
                console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
            }).error(function (data, status, headers, config) {
                console.log('error status: ' + status);
                $scope.errors = data;
                $('.alert.alert-error').fadeIn();
            });
        }
    };

    $scope.displayMethod = function(id) {
        return !_.contains(hiddenMethods, id);
    };

    $scope.displayDelai = function(id) {
        return !_.contains(hiddenTimes, id);
    };

    $scope.destroyMethod = function(id) {
        Alert.confirm(function() { return PaymentMethod.destroy(id) }, function() {  hiddenMethods.push(id) });
    };
    $scope.destroyDelai = function(id) {
        Alert.confirm(function() { return PaymentTime.destroy(id) }, function() {  hiddenTimes.push(id) });
    };
});