angular.module('invoiceService', []).factory('Invoice', function($http) {

    return {
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'invoices/' + id,
                data: {_method: 'DELETE'},
            });
        },
    };
});

