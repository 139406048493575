angular.module('dealTypeService', []).factory('DealType', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/deal-types',
            });
        },
        destroy: function(dealTypeId) {
            return $http({
                method: 'POST',
                url: 'admin/deal-types/' + dealTypeId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
