angular.module('documentsController', []).controller('documentsController', function($scope, Document, Alert) {

    $scope.loading = false;

    var hiddenDocuments = [];

    
    $scope.displayDocument = function(documentId) {
        return !_.contains(hiddenDocuments, documentId);
    };

    $scope.destroyDocument = function(documentId) {        
        Alert.confirm(function() { return Document.destroy(documentId) }, function() { hiddenDocuments.push(documentId) });
    };

}); 