angular.module('addressesController', []).controller('addressesController', function($scope, Address, Alert) {

    $scope.loading = false;

    var hiddenAddresses = [];
    

    $scope.displayAddress = function(addressId) {
        return !_.contains(hiddenAddresses, addressId);
    };

    $scope.destroyAddress = function(addressId) {        
        Alert.confirm(function() { return Address.destroy(addressId) }, function() { hiddenAddresses.push(addressId) });
    };
});