angular.module('devisService', []).factory('Devis', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'devis',
            });
        },
        getInvoices: function() {
            return $http({
                method: 'GET',
                url: 'invoices',
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'devis/' + id,
                data: {_method: 'DELETE'},
            });
        },
        destroyLine: function(id) {
            return $http({
                method: 'POST',
                url: 'devis_lines/' + id,
                data: {_method: 'DELETE'},
            });
        },
    };
});

