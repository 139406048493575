angular.module('dealsController', []).controller('dealsController', function($translate, $scope, Deal, DealType, Machine, Client, Alert, Article, Estimation, Stock, Technical, Piece) {

    $scope.loading = false;

    var hiddenDeals = [];

    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };

    Deal.get().success(function (data) { $scope.deals = data; });
    Client.get().success(function (data) { $scope.clients = data; });
    Machine.get().success(function (data) { $scope.machines = data; });
    Article.get().success(function (data) { $scope.articles = data; });
    
    $scope.displayDeal = function(dealId) {
        return !_.contains(hiddenDeals, dealId);
    };

    $scope.destroyDeal = function(dealId) {        
        Alert.confirm(function() { return Deal.destroy(dealId) }, function() { hiddenDeals.push(dealId) });
    };

    $scope.destroyDealView = function(dealId) {
        Alert.confirm(function() { return Deal.destroy(dealId) }, function() {
            location.replace('/deals');
        });
    };

    $scope.destroyDealInvoice = function(invoiceId) {        
        Alert.confirm(function() { return Deal.destroyDealInvoice(invoiceId) }, function() { location.reload() });
    };

    $scope.destroyBL = function(blId) {        
        Alert.confirm(function() { return Deal.destroyBL(blId) }, function() { location.reload() });
    };

    $scope.destroyDealEstimation = function(estimationId) {
        Alert.confirm(function() { return Estimation.destroy(estimationId) }, function() { location.reload() });
    };
    $scope.destroyStock = function(id) {
        Alert.confirm(function() { return Stock.destroy(id) }, function() { location.reload() });
    };
    $scope.destroyTechnical = function(id, deal) {
        Alert.confirm(function() { return Technical.destroy(id) }, function() { location.replace('/deals/' + deal + '/technicals');});
    };
    $scope.destroyPiece = function(id) {
        Alert.confirm(function() { return Piece.destroy(id) }, function() { location.reload() });
    };

    //get selected client for edit deal
    $scope.selectedDealClient = function(dealId) {
        Deal.getSelectedClient(dealId).success(function (data) { $scope.selectedDesignation = data; });
    };

    //get selected categories for edit providers
    $scope.selectedTypes = function(dealId) {
        Deal.getEdit(dealId).success(function (data) { $scope.selectedItems = data; });
    };

    //get selected contact for edit deal
    $scope.selectedDealContacts = function(dealId) {
        Deal.getSelectedContacts(dealId).success(function (data) { $scope.selectedContacts = data; });
    };

    //get contacts for create deal
    $scope.selectContacts = function(clientId) {
        Client.getContact(clientId).success(function (data) { $scope.contacts = data; });
    };

    $scope.clearContacts = function() {
        $scope.selectedContacts = [];
    };

    $scope.clientAddresses = function(clientId) {
        Client.getAddresses(clientId).success(function (data) {
            $scope.addresses = data;
            if ($scope.addresses == 0) { $scope.hasAddresses = false; } else { $scope.hasAddresses = true; }
        });
    }

    //get list of categories
    DealType.get().success(function (data) { $scope.types = data; });

    $scope.selectedDesignation = {};
    $scope.selectedItems = [];
    $scope.add_client = 2;
    $scope.add_address = 2;
    $scope.selectedContacts = [];
    $scope.addresses = [];
    $scope.address = null;
    $scope.address_id = null;
    $scope.hasAddresses = true;
    $scope.preSelectedDeal = null;
    $scope.preSelectedClient = null;

    Client.get().success(function (data) {
        $scope.clients = data;
        if (!$scope.clients.length) {
            $scope.add_client = 1;
        } else {
            if ($scope.preSelectedClient != '') {
                for (var j = 0; j < $scope.clients.length; j++) {
                    if($scope.clients[j].id == $scope.preSelectedClient) {
                        $scope.selectedDesignation = $scope.clients[j];
                        break;
                    }
                }
            }
            else {
                 $scope.selectedDesignation = null;
            }
        }
    });

    $scope.onArticleSelect = function ($item, $model, $label, e) {
        if (e != '') {
            $scope['selectedDesignation' + e] = $item.designation
        } else {
            $scope.selectedDesignation = $item.designation
        }
    };

    $scope.settingsTypes = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };

    $scope.settingsContacts = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "fullNameDesignation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };

    $scope.translateSelect = {buttonDefaultText: 'Selection des types d\'affaire', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'type(s) d\'affaire sélectionné(s)', searchPlaceholder: 'Rechercher...'}
    $scope.translateContact = {buttonDefaultText: 'Selection des contacts', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'contact(s) sélectionné(s)', searchPlaceholder: 'Rechercher...'}

    $scope.image = $('#signature-pad').data( "img" );
});
