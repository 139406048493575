angular.module('chartsController', ['chart.js']).controller('chartsController', function($scope, Deal, Order, Tracker) {

    $scope.dealsLabels = [];
    $scope.dealsData = [];
    //Deals data
    Deal.getStats().success(function (data) {
        $scope.dealsData[0] = data.values;
        $scope.dealsLabels = data.labels;
    });
    // Orders data
    $scope.ordersLabels = [];
    $scope.ordersData = [];

    Order.getStats().success(function (data) {
        $scope.ordersData[0] = data.values;
        $scope.ordersLabels = data.labels;
    });
    // Trackers
    $scope.trackersLabels = [];
    $scope.trackers = [];

    Tracker.get().success(function(data) {
        $scope.trackers[0] = data.values;
        $scope.trackersLabels = data.labels;
    });

    // Devis trackers data
    $scope.devisTrackersLabels = [];
    $scope.devis_trackers = [];


    Tracker.getDevis().success(function(data) {
        $scope.devis_trackers[0] = data.values;
        $scope.devisTrackersLabels = data.labels;
    });

    // Command trackers data
    $scope.commandTrackersLabels = [];
    $scope.command_trackers = [];


    Tracker.getCommand().success(function(data) {
        $scope.command_trackers[0] = data.values;
        $scope.commandTrackersLabels = data.labels;
    });

    // Invoices trackers data
    $scope.invoicesTrackersLabels = [];
    $scope.invoices_trackers = [];


    Tracker.getInvoices().success(function(data) {
        $scope.invoices_trackers[0] = data.values;
        $scope.invoicesTrackersLabels = data.labels;
    });


    // Payments trackers data
    $scope.paymentsTrackersLabels = [];
    $scope.payments_trackers = [];


    Tracker.getPayments().success(function(data) {
        $scope.payments_trackers[0] = data.values;
        $scope.paymentsTrackersLabels = data.labels;
    });
});