angular.module('machineService', []).factory('Machine', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/machines',
            });
        },
        destroy: function(machineId) {
            return $http({
                method: 'POST',
                url: 'admin/machines/' + machineId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
