angular.module('contactsController', []).controller('contactsController', function($scope, Contact, Alert) {

    $scope.loading = false;

    var hiddenContacts = [];
    
    $scope.displayContact = function(contactId) {
        return !_.contains(hiddenContacts, contactId);
    };

    $scope.destroyContact = function(contactId) {        
        Alert.confirm(function() { return Contact.destroy(contactId) }, function() { hiddenContacts.push(contactId) });
    };
});