function setNanoScroller() {
    $('.nano').nanoScroller({ alwaysVisible: false, iOSNativeScrolling: false });
}

function responsive() {
    if ($(window).width() < 1240) {
        $('.left-nav, .header-logo').addClass('close');
        $('.dashboard-page').addClass('full');
    } else {
        $('.left-nav, .header-logo').removeClass('close');
        $('.dashboard-page').removeClass('full');
    }
    setNanoScroller();
}

$(function() {
    $("[data-toggle='tooltip']").tooltip();

    $('#dropdownMenu1').on('shown.bs.dropdown', setNanoScroller);

    $('.ion-navicon-round').click(function() {
        $('.left-nav, .header-logo').toggleClass('close');
        $('.dashboard-page').toggleClass('full');
        setNanoScroller();
    });

    $('.nav-menu-responsive').click(function() {
        $('.nav-menu-links').toggleClass('responsive');
        $('.header').toggleClass('responsive');
        setNanoScroller();
    });
    responsive();
    _.delay(setNanoScroller, 150);

});

$('.delete').on('submit', function(){
	return confirm("Do you want to delete this item?");
});

var app = angular.module('app', [
    'mainController',
    'rolesController',
    'usersController',
    'settingsController',
    'passwordController',
    'machinesController',
    'articleCategoriesController',
    'dealTypesController',
    'clientsController',
    'addressesController',
    'contactsController',
    'providersController',
    'articlesController',
    'operationTypesController',
    'ordersController',
    'documentsController',
    'dealsController',
    'operationsController',
    'stocksController',
    'purchasesController',
    'homeController',
    'skillsController',
    'roleService',
    'userService',
    'alertService',
    'routeService',
    'machineService',
    'articleCategoryService',
    'dealTypeService',
    'clientService',
    'addressService',
    'contactService',
    'providerService',
    'articleService',
    'dealService',
    'operationService',
    'operationTypeService',
    'orderService',
    'documentService',
    'stockService',
    'purchaseService',
    'estimationService',
    'skillService',
    'angularMoment',
    'oitozero.ngSweetAlert',
    'pascalprecht.translate',
    'ngFileUpload',
    'validator.rules',
    'ngAnimate',
    'angular-datepicker',
    'ui.bootstrap',
    'ngMap',
    'angular-cache',
    'chart.js',
    'chartsController',
    'angularjs-dropdown-multiselect',
    'checkingController',
    'checkingService',
    'statesController',
    'stateService',
    'devisController',
    'devisService',
    'invoiceService',
    'paymentService',
    'paymentMethodService',
    'paymentTimeService',
    'lineService',
    'pieceService',
    'ngRoute',
    'offline',
	'signature',
    'colorpicker.module',
    'angular-svg-round-progressbar',
    'technicalService',
    'avoirService',
    'trackerService',
    'interfaceController'
]);

var app_checking = angular.module('app-checking', [
    'ngRoute',
    'mainController',
    'checkingController',
    'scanController',
    'userService',
    'dealService',
    'scanService',
    'checkingService',
    'alertService',
    'oitozero.ngSweetAlert',
    'pascalprecht.translate',
    'angular-cache',
    'offline'
]);

app_checking.config(['$routeProvider',
  function($routeProvider) {
    $routeProvider.
      when('/', {
        templateUrl: 'ng-templates/checking/step1',
        controller: 'checkingController'
      }).
      when('/step1/:success', {
        templateUrl: 'ng-templates/checking/step1',
        controller: 'checkingController'
      }).
      when('/step2/:login', {
        templateUrl: 'ng-templates/checking/step2',
        controller: 'checkingController'
      }).
      when('/step3', {
        templateUrl: 'ng-templates/checking/step3',
        controller: 'checkingController'
      }).
      otherwise({
        redirectTo: '/'
      });
  }]);



app.config(function ($translateProvider, $httpProvider, offlineProvider) {

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    $translateProvider.useSanitizeValueStrategy('escaped');

    offlineProvider.debug(true);

    $translateProvider.translations('fr', {
        sweetalert: {
            title: "Etes-vous sûr ?",
            text: "Vous ne pourrez plus récupérer cet élément !",
            confirmButtonText: "Oui, supprimez-le !",
            cancelButtonText: "Annuler",
            success: {
                title: "Supprimé !",
                text: "Cet élément a été supprimé."
            },
            error: {
                title: "Annulé",
                text: "Cet élément n'a pas été supprimé."
            }
        },
        datepicker: {
            monthsFull: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
            monthsShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ],
            weekdaysFull: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
            weekdaysShort: [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ],
            today: "Aujourd'hui",
            clear: 'Effacer',
            close: 'Fermer',
            firstDay: '1',
            format: 'dd-mm-yyyy'
        },
        onbeforeunload: "Attention ! Des changements non sauvegardés ont été effectués. Etes-vous sûr de vouloir quitter cette page ?"
    });

    $translateProvider.preferredLanguage("fr");
});

app.run(function(amMoment, $http, $cacheFactory, CacheFactory, offline) {

    $http.defaults.cache = $cacheFactory('custom');

    amMoment.changeLocale($('html').attr('lang'));

    offline.stackCache = CacheFactory.createCache('my-cache', { storageMode: 'localStorage' });
    offline.start($http);
});

app_checking.run(function($http, $cacheFactory, CacheFactory, offline) {

    $http.defaults.cache = $cacheFactory('custom');

    offline.stackCache = CacheFactory.createCache('my-cache', { storageMode: 'localStorage' });
    offline.start($http);
});