angular.module('operationsController', []).controller('operationsController', function($scope, Operation, Machine, Alert, Skill) {

    $scope.loading = false;

    var hiddenOperations = [];
    
    $scope.displayOperation = function(operationId) {
        return !_.contains(hiddenOperations, operationId);
    };

    $scope.destroyOperation = function(operationId) {        
        Alert.confirm(function() { return Operation.destroy(operationId) }, function() { hiddenOperations.push(operationId) });
    };

    /**
     * Get all available skills
     */
    Skill.get().success(function (data) { $scope.skills = data; });

    /**
     * Get skills associated with selected user
     */
    $scope.selectedSkills = [];
    $scope.selectedOperationSkills = function(operationId) {
        Operation.getSkills(operationId).success(function (data) { $scope.selectedSkills = data; });
    };

    $scope.settingsSkills = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };
    $scope.translateSkill = {buttonDefaultText: 'Selection des compétences', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'compétence(s) sélectionnée(s)', searchPlaceholder: 'Rechercher...'}
});