angular.module('trackerService', []).factory('Tracker', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'trackers',
            });
        },
        getCommand: function() {
            return $http({
                method: 'GET',
                url: 'command_trackers',
            });
        },
        getDevis: function() {
            return $http({
                method: 'GET',
                url: 'devis_trackers',
            });
        },
        getInvoices: function() {
            return $http({
                method: 'GET',
                url: 'invoices_trackers',
            });
        },
        getPayments: function() {
            return $http({
                method: 'GET',
                url: 'payments_trackers',
            });
        },
    };
});
