angular.module('articleCategoryService', []).factory('ArticleCategory', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/article-categories',
            });
        },
        destroy: function(articleCategoryId) {
            return $http({
                method: 'POST',
                url: 'admin/article-categories/' + articleCategoryId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
