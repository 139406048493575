angular.module('articleService', []).factory('Article', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'articles',
            });
        },
        getEdit: function(articleId) {
            return $http({
                method: 'GET',
                url: 'articles/'+ articleId +'/edit',
            });
        },
        destroy: function(articleId) {
            return $http({
                method: 'POST',
                url: 'articles/' + articleId,
                data: {_method: 'DELETE'},
            });
        },
    };
});
