angular.module('clientsController', []).controller('clientsController', function($scope, Client, Alert) {

    $scope.loading = false;

    var hiddenClients = [];

    Client.get().success(function (data) { $scope.clients = data; });

    $scope.displayClient = function(clientId) {
        return !_.contains(hiddenClients, clientId);
    };

    $scope.destroyClient = function(clientId) {
        Alert.confirm(function() { return Client.destroy(clientId) }, function() { hiddenClients.push(clientId) });
    };

    $scope.getAddresses = function(clientId) {
    	Client.getAddresses(clientId).success(function (data) { $scope.addresses = data; });
    }

    $scope.addresses = [];
});