angular.module('paymentTimeService', []).factory('PaymentTime', function($http) {

    return {
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'admin/payments_times/' + id,
                data: {_method: 'DELETE'},
            });
        },
    };
});

