angular.module('purchaseService', []).factory('Purchase', function($http) {

    return {
        destroy: function(purchaseId) {
            return $http({
                method: 'POST',
                url: 'purchases/' + purchaseId,
                data: {_method: 'DELETE'},
            });
        },
        isStocked: function(purchaseId) {
            return $http({
                method: 'GET',
                url: 'purchases/stock/' + purchaseId,
            });
        },
    };
});
