angular.module('operationService', []).factory('Operation', function($http) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: 'operations',
            });
        },
        getOperations: function(affaireDesignation) {
            return $http({
                method: 'GET',
                url: 'getOperations/' + affaireDesignation ,
            });
        },
        destroy: function(operationId) {
            return $http({
                method: 'POST',
                url: 'operations/' + operationId,
                data: {_method: 'DELETE'},
            });
        },
        getSkills: function(operationId) {
            return $http({
                method: 'GET',
                url: 'operations/skills/'+ operationId,
            });
        },
    };
});
