angular.module('operationTypeService', []).factory('OperationType', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'operation_types',
            });
        },
        getEdit: function(operationTypeId) {
            return $http({
                method: 'GET',
                url: 'operation_types/'+ operationTypeId +'/edit',
            });
        },
        destroy: function(operationTypeId) {
            return $http({
                method: 'POST',
                url: 'operation_types/' + operationTypeId,
                data: {_method: 'DELETE'},
            });
        },
        getSkills: function(operationId) {
            return $http({
                method: 'GET',
                url: 'operation_types/skills/'+ operationId,
            });
        },
    };
});
