angular.module('stateService', []).factory('State', function($http) {

	return {
		destroy: function(id) {
			return $http({
				method: 'DELETE',
				url: 'admin/states/' + id,
			});
		},
	};
});

