angular.module('stocksController', []).controller('stocksController', function($translate, $scope, Stock, Article, Alert) {

    $scope.stocks = []
    $scope.options_create_date = {
        monthsFull: [
            $translate.instant('datepicker.monthsFull.0'),
            $translate.instant('datepicker.monthsFull.1'),
            $translate.instant('datepicker.monthsFull.2'),
            $translate.instant('datepicker.monthsFull.3'),
            $translate.instant('datepicker.monthsFull.4'),
            $translate.instant('datepicker.monthsFull.5'),
            $translate.instant('datepicker.monthsFull.6'),
            $translate.instant('datepicker.monthsFull.7'),
            $translate.instant('datepicker.monthsFull.8'),
            $translate.instant('datepicker.monthsFull.9'),
            $translate.instant('datepicker.monthsFull.10'),
            $translate.instant('datepicker.monthsFull.11')
        ],
        monthsShort: [
            $translate.instant('datepicker.monthsShort.0'),
            $translate.instant('datepicker.monthsShort.1'),
            $translate.instant('datepicker.monthsShort.2'),
            $translate.instant('datepicker.monthsShort.3'),
            $translate.instant('datepicker.monthsShort.4'),
            $translate.instant('datepicker.monthsShort.5'),
            $translate.instant('datepicker.monthsShort.6'),
            $translate.instant('datepicker.monthsShort.7'),
            $translate.instant('datepicker.monthsShort.8'),
            $translate.instant('datepicker.monthsShort.9'),
            $translate.instant('datepicker.monthsShort.10'),
            $translate.instant('datepicker.monthsShort.11')
        ],
        weekdaysFull: [
            $translate.instant('datepicker.weekdaysFull.0'),
            $translate.instant('datepicker.weekdaysFull.1'),
            $translate.instant('datepicker.weekdaysFull.2'),
            $translate.instant('datepicker.weekdaysFull.3'),
            $translate.instant('datepicker.weekdaysFull.4'),
            $translate.instant('datepicker.weekdaysFull.5'),
            $translate.instant('datepicker.weekdaysFull.6')
        ],
        weekdaysShort: [
            $translate.instant('datepicker.weekdaysShort.0'),
            $translate.instant('datepicker.weekdaysShort.1'),
            $translate.instant('datepicker.weekdaysShort.2'),
            $translate.instant('datepicker.weekdaysShort.3'),
            $translate.instant('datepicker.weekdaysShort.4'),
            $translate.instant('datepicker.weekdaysShort.5'),
            $translate.instant('datepicker.weekdaysShort.6')
        ],
        today:        $translate.instant('datepicker.today'),
        clear:        $translate.instant('datepicker.clear'),
        close:        $translate.instant('datepicker.close'),
        firstDay:     parseInt($translate.instant('datepicker.firstDay')),
        format:       $translate.instant('datepicker.format'),
        formatSubmit: 'yyyy-mm-dd'
    };

    $scope.loading = false;

    var hiddenStocks = [];

    Stock.get().success(function (data) { $scope.stocks = data; });
    Article.get().success(function (data) { $scope.articles = data; });
    
    $scope.displayStock = function(stockId) {
        return !_.contains(hiddenStocks, stockId);
    };

    $scope.destroyStock = function(stockId) {        
        Alert.confirm(function() { return Stock.destroy(stockId) }, function() { hiddenStocks.push(stockId) });
    };

    $scope.autoSelectArticle = function(articleId) {
        if (articleId) {
            $scope.selectedItems = articleId
        }
    }

    $scope.fillForm = function(articleId, quantity) {
        var find = false;
        for(var i = 0; i < $scope.stocks.length; i++) {
            if ($scope.stocks[i].id == articleId) {
                $scope.selectedItems = {id: articleId}
                document.getElementById("quantityInput").value = quantity
                find = true
                break;
            }
        }

        if (!find) {
            alert("Il n'y a pas de stocks enregistrés pour cet article");
        }
    }

    $scope.selectedItems = {};
    $scope.settingsStocks = {
        enableSearch: true, 
        idProp: 'id',
        displayProp: "designation",
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200,
        showCheckAll: false,
        showUncheckAll: false,
        selectionLimit: 1,
        closeOnSelect: true,
        dynamicTitle: true,
        smartButtonMaxItems: 1
    };
    $scope.translateSelect = {buttonDefaultText: 'Selection des articles en stock', dynamicButtonTextSuffix: 'article selectionné'}
});
