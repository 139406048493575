angular.module('operationTypesController', []).controller('operationTypesController', function($scope, OperationType, Alert, Machine, Skill) {

    $scope.loading = false;

    var hiddenOperationTypes = [];

    OperationType.get().success(function (data) { $scope.operation_types = data; });
    
    Machine.get().success(function (data) { $scope.machines = data; });
    
    $scope.displayOperationType = function(operationTypeId) {
        return !_.contains(hiddenOperationTypes, operationTypeId);
    };

    $scope.destroyOperationType = function(operationTypeId) {        
        Alert.confirm(function() { return OperationType.destroy(operationTypeId) }, function() { hiddenOperationTypes.push(operationTypeId) });
    };

    /**
     * Get all available skills
     */
    Skill.get().success(function (data) { $scope.skills = data; });

    /**
     * Get skills associated with selected user
     */
    $scope.selectedSkills = [];
    $scope.selectedOperationTypeSkills = function(operationId) {
        OperationType.getSkills(operationId).success(function (data) { $scope.selectedSkills = data; });
    };

    $scope.settingsSkills = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "designation",
        externalIdProp: 'id',
        buttonClasses: "btn-dropdown",
        scrollable: true,
        scrollableHeight: 200
    };
    $scope.translateSkill = {buttonDefaultText: 'Selection des compétences', checkAll: 'Tout sélectionner', uncheckAll: 'Tout désélectionner', dynamicButtonTextSuffix: 'compétence(s) sélectionnée(s)', searchPlaceholder: 'Rechercher...'}
}); 