angular.module('dealService', []).factory('Deal', function($http) {

    return {
        getJSON: function() {
            return $http({
                method: 'GET',
                url: 'checking/deals.json'
            });
        },
        get: function() {
            return $http({
                method: 'GET',
                url: 'deals',
            });
        },
        getDeal: function(dealid) {
            return $http({
                method: 'GET',
                url: 'deal/'+ dealid,
            });
        },
        getEdit: function(dealId) {
            return $http({
                method: 'GET',
                url: 'deals/'+ dealId +'/edit',
            });
        },
        destroy: function(dealId) {
            return $http({
                method: 'POST',
                url: 'deals/' + dealId,
                data: {_method: 'DELETE'},
            });
        },
        destroyBL: function(blId) {
            return $http({
                method: 'POST',
                url: 'destroy_delivery/' + blId,
            });
        },
        destroyDealInvoice: function(invoiceId) {
            return $http({
                method: 'POST',
                url: 'destroy_deal_invoice/' + invoiceId,
            });
        },
        getSelectedClient: function(dealId) {
            return $http({
                method: 'GET',
                url: 'deal/client/'+ dealId,
            });
        },
        getSelectedContacts: function(dealId) {
            return $http({
                method: 'GET',
                url: 'deal/contacts/'+ dealId,
            });
        },
        getStats: function() {
            return $http({
                method: 'GET',
                url: 'deals/stats'
            });
        },
    };
});

