angular.module('paymentService', []).factory('Payment', function($http) {

    return {
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'payments/' + id,
                data: {_method: 'DELETE'},
            });
        },
    };
});

