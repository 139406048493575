angular.module('providersController', []).controller('providersController', function($scope, Provider, ArticleCategory, Alert) {

    $scope.loading = false;

    var hiddenProviders = [];

    Provider.get().success(function (data) { $scope.providers = data; });
    
    $scope.displayProvider = function(providerId) {
        return !_.contains(hiddenProviders, providerId);
    };

    $scope.destroyProvider = function(providerId) {        
        Alert.confirm(function() { return Provider.destroy(providerId) }, function() { hiddenProviders.push(providerId) });
    };

    //get selected categories for edit providers
    $scope.selectedCategories = function(providerId) {
        Provider.getEdit(providerId).success(function (data) { $scope.selectedItems = data; });
    };

    //get list of categories
    ArticleCategory.get().success(function (data) { $scope.categories = data; });

    $scope.selectedItems = [];
    
	$scope.settingsCategories = {enableSearch: true, idProp: 'id',displayProp: "designation",externalIdProp: 'id', buttonClasses: "btn-dropdown", scrollable: true,scrollableHeight: 200};
});